import React from "react";
import { RequireAuth, useIsAuthenticated } from "react-auth-kit";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import FormPage from "./pages/FormPage";
import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AnalysesPage from "./pages/AnalysesPage";
import HelpCenterPage from "./pages/HelpCenterPage";
function App() {
  const clientId =
    "331611527191-q8bq3m7qq8e39c9j0sgl100up58vu92h.apps.googleusercontent.com";
  const PrivateRoute = ({ Component }: any) => {
    const isAuthenticated = useIsAuthenticated();
    const auth = isAuthenticated();
    return auth ? Component : <Navigate to="/login" />;
  };
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<PrivateRoute Component={<FormPage />} />} />
          <Route
            path="/profile"
            element={<PrivateRoute Component={<ProfilePage />} />}
          />
          <Route
            path="/helpcenter"
            element={<PrivateRoute Component={<HelpCenterPage />} />}
          />
          <Route
            path="/cereanswers/:id"
            element={<PrivateRoute Component={<AnalysesPage />} />}
          />
          {/* <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="/changepassword" element={<ChangePasswordPage />} /> */}
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
