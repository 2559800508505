import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function HelpCenterPage() {
  return (
    <div className="bg-gradient-to-b from-gray-700 via-gray-900 to-black min-h-screen flex flex-col justify-between">
      <Navbar />
      <Footer />
    </div>
  );
}

export default HelpCenterPage;
