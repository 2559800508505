import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface Form {
  loading: boolean;
  emotionAnswer: string | null;
  categoryAnswer: string | null;
  satisfactionAnswer: string | null;
  callToActionAnswer: string | null;
  emailAnswer: string | null;
  audioContent: string | null;
  summaryAnswer: string | null;
}

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;

const initialState: Form = {
  loading: false,
  emotionAnswer: null,
  categoryAnswer: null,
  satisfactionAnswer: null,
  callToActionAnswer: null,
  emailAnswer: null,
  audioContent: null,
  summaryAnswer: null
};

interface formRequestProps {
  inputValue: string;
  token: string;
}

export const sendFormToAnalyse = createAsyncThunk(
  "form/response",
  async ({ inputValue, token }: formRequestProps) => {
    const {
      data: { data }
    } = await axios.post(
      `${BASE_URL}/api/openAi/customerFrom`,
      {
        formText: inputValue
      },
      {
        headers: {
          Authorization: token
        }
      }
    );

    return data;
  }
);

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    // formResponse: (state, { payload }) => {
    //   console.log(payload);
    //   state.emotionAnswer = payload.emotionAnswer;
    //   state.categoryAnswer = payload.categoryAnswer;
    //   state.satisfactionAnswer = payload.satisfactionAnswer;
    //   state.callToActionAnswer = payload.callToActionAnswer;
    //   state.emailAnswer = payload.emailAnswer;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(sendFormToAnalyse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendFormToAnalyse.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.emotionAnswer = payload.emotionAnswer;
      state.categoryAnswer = payload.categoryAnswer;
      state.satisfactionAnswer = payload.satisfactionAnswer;
      state.callToActionAnswer = payload.callToActionAnswer;
      state.emailAnswer = payload.emailAnswer;
      state.audioContent = payload.audioContent.audioContent;
      state.summaryAnswer = payload.summaryAnswer;
    });
    builder.addCase(sendFormToAnalyse.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const {} = formSlice.actions;

export default formSlice.reducer;
