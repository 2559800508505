import { AnyAction } from "@reduxjs/toolkit";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import { sendFormToAnalyse } from "../redux/features/formSlice";
import { useTranslation } from "react-i18next";

const FormInputs = () => {
  const authState = useAuthHeader();
  const token = authState();
  const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
    if (event.target.value?.length > 2000) {
      setErrorMessage("Character limit exceeded");
    } else {
      setErrorMessage("");
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    dispatch(sendFormToAnalyse({ inputValue, token }) as any);
  };

  function deleteCompanyText() {
    setInputValue("");
  }

  return (
    <div className="w-full my-2">
      <h1 className="text-center text-white font-semibold text-xl my-1">
        {t("components.formInput.title")}
      </h1>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center w-full"
      >
        <div className="flex space-x-2 w-full">
          <textarea
            className="w-full p-2 mb-2 border bg-transparent border-slate-400 rounded-md text-white font-medium"
            placeholder={`${t("components.formInput.placeholder")}`}
            value={inputValue}
            onChange={handleInputChange}
            maxLength={2000}
            rows={5}
          />
          <div className="flex flex-col space-y-2 justify-center">
            <button
              onClick={deleteCompanyText}
              className="bg-gradient-to-r from-red-200 to-red-600  text-white font-bold py-2 px-4 rounded"
              disabled={inputValue?.length === 0 || errorMessage !== ""}
            >
              {t("components.formInput.button1")}
            </button>
            <button
              type="submit"
              className="bg-gradient-to-r from-green-200 via-green-400 to-green-500 text-white font-bold py-2 px-4 rounded"
              disabled={inputValue?.length === 0 || errorMessage !== ""}
            >
              {t("components.formInput.button2")}
            </button>
          </div>
        </div>
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      </form>
    </div>
  );
};

export default FormInputs;
