import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";

function FormResponse() {
  const {
    emotionAnswer,
    categoryAnswer,
    callToActionAnswer,
    emailAnswer,
    audioContent,
    summaryAnswer,
    loading,
  } = useSelector((state: RootState) => state.form);
  const { t, i18n } = useTranslation();

  const [audioKey, setAudioKey] = useState(0);

  useEffect(() => {
    setAudioKey((prevKey) => prevKey + 1);
  }, [audioContent]);

  function replaceNewlineWithBr(text: string | null) {
    if (text) {
      return text.replace(/\n/g, " ");
    }
  }

  return (
    <>
      {!loading && emotionAnswer && (
        <div className="flex flex-col space-y-2 w-full bg-transparent text-white rounded-lg items-center justify-center">
          {audioContent && (
            <audio controls hidden autoPlay key={audioKey}>
              <source
                src={`data:audio/mp3;base64,${audioContent}`}
                type="audio/mp3"
              />
            </audio>
          )}

          <div className="px-2 py-1">
            <div className="flex flex-row space-x-1 items-center">
              <h1 className="text-lg">
                <span className="font-semibold">
                  {t("components.analyseDetail.text1")}:
                </span>{" "}
                {categoryAnswer}
              </h1>
            </div>

            <div className="flex flex-row space-x-1 items-center">
              <h1 className="text-lg">
                <span className="font-semibold">
                  {t("components.analyseDetail.text2")}:{" "}
                </span>
                {emotionAnswer}
              </h1>
            </div>
            <div className="flex flex-row space-x-1 items-center">
              <h1 className="text-lg">
                <span className="font-semibold">
                  {t("components.analyseDetail.text3")}:
                </span>{" "}
                {callToActionAnswer}
              </h1>
            </div>
            <div className="flex flex-row space-x-1 items-center">
              <h1 className="text-lg ">
                <span className="font-semibold">
                  {t("components.analyseDetail.text4")}:{" "}
                </span>{" "}
                {replaceNewlineWithBr(emailAnswer)}
              </h1>
            </div>
            <div className="flex flex-row space-x-1 items-center">
              <h1 className="text-lg ">
                <span className="font-semibold">
                  {t("components.analyseDetail.text5")}:
                </span>{" "}
                {summaryAnswer}
              </h1>
            </div>
            <div></div>
          </div>
        </div>
      )}
      {loading && (
        <div className="flex w-full items-center justify-center my-3">
          <Loading />
        </div>
      )}
    </>
  );
}

export default FormResponse;
