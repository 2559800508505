import React, { useEffect, useState } from "react";
import { responses } from "../../mocks";
import AnalyseTitle from "./AnalyseTitle";
import AnalyseDetail from "./AnalyseDetail";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useTranslation } from "react-i18next";

function AnalyseResponses() {
  const [showDetail, setShowDetail] = useState(false);
  const [selectedTitleID, setSelectedTitleID] = useState<null | number>(null);
  const complaintResponses = useSelector(
    (state: RootState) => state.complaintResponses.complaintResponses
  );
  const { t, i18n } = useTranslation();

  console.log(complaintResponses);

  const selectedAnalyse =
    complaintResponses &&
    complaintResponses.find((response) => response.id === selectedTitleID);

  return (
    <>
      <div className="relative border border-gray-400 flex w-4/6 mx-auto rounded-lg p-2">
        <div className="w-full h-full rounded-lg flex flex-col space-y-2 ">
          <h1 className="text-white text-2xl font-semibold pl-2">
            {t("components.analyseResponse.title")}
          </h1>
          <hr className="" />
          <div className="grid grid-cols-5 py-2 gap-x-3 gap-y-2 ">
            {complaintResponses &&
              complaintResponses.map((response) => (
                <AnalyseTitle
                  id={response.id}
                  key={response.id}
                  title={response.category}
                  setSelectedTitleID={setSelectedTitleID}
                  setShowDetail={setShowDetail}
                />
              ))}
          </div>
        </div>
      </div>
      {showDetail && (
        <AnalyseDetail
          selectedAnalyse={selectedAnalyse}
          setShowDetail={setShowDetail}
          setSelectedTitleID={setSelectedTitleID}
        />
      )}
    </>
  );
}

export default AnalyseResponses;
