import axios from "axios";
import React, { useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface CSVUploaderProps {
  onFileUpload: () => void;
}

const CSVUploader: React.FC<CSVUploaderProps> = ({ onFileUpload }) => {
  const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;
  const [csvFile, setCSVFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fileSent, setFileSent] = useState(false);
  const [bundleID, setBundleID] = useState<null | string>(null);
  const authState = useAuthHeader();
  const token = authState();
  const { t, i18n } = useTranslation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    if (!file.name.endsWith(".csv")) {
      setErrorMessage("Invalid file type. Please select a CSV file.");
      setCSVFile(null);
      return;
    }
    setErrorMessage(null);
    setCSVFile(file);
  };

  const config = {
    headers: {
      Authorization: token,
    },
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!csvFile) {
      setErrorMessage("Please select a CSV file to upload.");
      return;
    }
    const formData = new FormData();
    formData.append("file", csvFile);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/openAi/customerFroms`,
        formData,
        config
      );

      setBundleID(response.data.data.complaintBundleId);
      setFileSent(true);
      if (response) {
        setErrorMessage(null);
      }
    } catch (error: any) {
      if (error) {
        console.log(error);

        setErrorMessage(
          `${t(`components.errors.${error?.response?.data?.errorCode}`)}`
        );
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-between py-3 w-[350px] h-[300px] bg-transparent rounded-lg">
      <label className="flex flex-col justify-center items-center h-[250px]">
        <svg
          className="w-8 h-8"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 00-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 10-1.414-1.414L11 10.586V7z"
            clipRule="evenodd"
          />
        </svg>
        <h1 className="text-xl text-white">{t("components.uploader.text1")}</h1>
        <h1 className="text-white">{t("components.uploader.text2")}</h1>
        <input
          type="file"
          onChange={handleFileChange}
          className="w-[250px] h-[100px] border-dotted border-gray-400 border-2 p-2"
        />
      </label>
      {errorMessage && (
        <p className="text-red-500 text-center mb-2">{errorMessage}</p>
      )}
      {fileSent && (
        <div className="flex flex-col items-center justify-center my-2 -space-y-1">
          <p className="text-green-500 text-center mb-2">
            {t("components.uploader.sentMessage")}
          </p>
          <Link to={`/cereanswers/${bundleID}`}>
            <span className="text-white font-semibold underline text-base">
              {t("components.uploader.directToID")}
            </span>
          </Link>
        </div>
      )}
      <button
        onClick={handleSubmit}
        className="w-[100px] bg-slate-400 rounded-lg text-xl font-semibold text-white"
      >
        {t("components.uploader.button")}
      </button>
    </div>
  );
};

export default CSVUploader;
