import React, { useState } from "react";
import Footer from "../components/Footer";
import FromInputs from "../components/FormInputs";
import FormResponse from "../components/FormResponse";
import Navbar from "../components/Navbar";
import UploadOptions from "../components/UploadOptions";

function FormPage() {
  return (
    <div className="bg-gradient-to-b from-gray-700 via-gray-900 to-black min-h-screen flex flex-col justify-between">
      <Navbar />
      <UploadOptions />

      <Footer />
    </div>
  );
}

export default FormPage;
