import React from "react";

interface AnalyseResponsesProps {
  title: string;
  id: number;
  setShowDetail: (boolean: boolean) => void;
  setSelectedTitleID: (number: number) => void;
}

function AnalyseTitle({
  title,
  id,
  setShowDetail,
  setSelectedTitleID,
}: AnalyseResponsesProps) {
  function handleClick() {
    setShowDetail(true);
    setSelectedTitleID(id);
  }
  return (
    <div
      className="border border-gray-400 rounded-md cursor-pointer px-2 py-1"
      onClick={handleClick}
    >
      <h1 className="text-white text-lg font-medium text-center">{title}</h1>
    </div>
  );
}

export default AnalyseTitle;
