import React, { useEffect, useMemo, useState } from "react";
import { BsPencil } from "react-icons/bs";
import { BiHomeSmile } from "react-icons/bi";
import { VscAccount } from "react-icons/vsc";
import { MdOutlineHelpOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { GiReceiveMoney } from "react-icons/gi";
import axios from "axios";
import Cookies from "js-cookie";
import { RootState, useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { fetchUserTokenLeft } from "../../redux/features/userTokenLeftSlice";
import { useTranslation } from "react-i18next";
function UserInfo({ signOut }: any) {
  const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;
  const { t, i18n } = useTranslation();
  const lng = navigator.language;
  const token = Cookies.get("access_token");
  const dispatch = useAppDispatch();
  const tokenLeft = useSelector(
    (state: RootState) => state.userTokenLeft.tokenLeft
  );

  useEffect(() => {
    dispatch(fetchUserTokenLeft({ token, BASE_URL }));
  }, []);

  return (
    <div className="bg-black/40 w-full h-full border-2 border-white ">
      <div className="border-b border-gray-300">
        <div className="flex p-2 space-x-3 cursor-pointer">
          <GiReceiveMoney size={24} color="#7A7479" />{" "}
          <span className="text-white">
            {t("components.navbar.main.text3")} :
            <span className="font-semibold"> {tokenLeft}</span>
          </span>{" "}
        </div>
        <Link to="/profile">
          <div className="flex p-2 space-x-3 cursor-pointer">
            <VscAccount size={24} color="#7A7479" />{" "}
            <span className="text-white">
              {" "}
              {t("components.navbar.main.text4")}
            </span>{" "}
          </div>
        </Link>
        <Link to="/helpcenter">
          <div className="flex p-2 space-x-3 cursor-pointer">
            <MdOutlineHelpOutline size={24} color="#7A7479" />{" "}
            <span className="text-white">
              {" "}
              {t("components.navbar.main.text5")}
            </span>{" "}
          </div>
        </Link>
      </div>
      <div
        className="flex items-center justify-center mt-2 cursor-pointer p-2"
        onClick={() => signOut()}
      >
        <h1 className="text-white text-center text-lg">
          {" "}
          {t("components.navbar.main.text6")}
        </h1>
      </div>
    </div>
  );
}

export default UserInfo;
