import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import FormReducer from "./features/formSlice";
import UserReducer from "./features/userSlice";
import UserTokenLeftReducer from "./features/userTokenLeftSlice";
import ComplaintResponsesReducer from "./features/complaintResponsesSlice";
import persistStore from "redux-persist/es/persistStore";
export const store = configureStore({
  reducer: {
    form: FormReducer,
    user: UserReducer,
    userTokenLeft: UserTokenLeftReducer,
    complaintResponses: ComplaintResponsesReducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
