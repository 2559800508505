import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface AnalyseDetailProps {
  selectedAnalyse: any;
  setShowDetail: (boolean: boolean) => void;
  setSelectedTitleID: (number: number | null) => void;
}

function AnalyseDetail({
  selectedAnalyse,
  setShowDetail,
  setSelectedTitleID
}: AnalyseDetailProps) {
  const {
    audioContent,
    category,
    emotion,
    callToAction,
    emailsuggestion,
    summary,
    id
  } = selectedAnalyse;
  const [audioKey, setAudioKey] = useState(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setAudioKey((prevKey) => prevKey + 1);
  }, [audioContent]);

  function handleClose() {
    setShowDetail(false);
    setSelectedTitleID(null);
  }

  return (
    <div className="w-full h-full absolute flex items-center justify-center bg-black/40">
      <div className="transform w-5/6 lg:w-1/3  right z-10 ">
        <div
          className="absolute top-3 right-4 cursor-pointer"
          onClick={handleClose}
        >
          <h1 className="text-lg font-bold">X</h1>
        </div>
        <div className="flex flex-col space-y-2 h-full bg-white rounded-lg items-center justify-center w-full p-4">
          <div className="px-2 py-1 overflow-auto no-scrollbar h-[600px]">
            <div className="flex flex-row space-x-1 items-center">
              <h1 className="text-lg">
                <span className="font-semibold">
                  {" "}
                  {t("components.analyseDetail.text1")}:
                </span>{" "}
                {category}
              </h1>
            </div>

            <div className="flex flex-row space-x-1 items-center">
              <h1 className="text-lg">
                <span className="font-semibold">
                  {t("components.analyseDetail.text2")}:{" "}
                </span>
                {emotion}
              </h1>
            </div>
            <div className="flex flex-row space-x-1 items-center">
              <h1 className="text-lg">
                <span className="font-semibold">
                  {t("components.analyseDetail.text3")}:
                </span>{" "}
                {callToAction}
              </h1>
            </div>
            <div className="flex flex-row space-x-1 items-center">
              <h1 className="text-lg ">
                <span className="font-semibold">
                  {t("components.analyseDetail.text4")}:
                </span>{" "}
                {emailsuggestion}
              </h1>
            </div>
            <div className="flex flex-row space-x-1 items-center">
              <h1 className="text-lg ">
                <span className="font-semibold">
                  {t("components.analyseDetail.text5")} :
                </span>{" "}
                {summary}
              </h1>
            </div>
            <div></div>
          </div>
        </div>

        {/* {loading && (
        <div className="flex w-2/3 items-center justify-center">
          <Loading />
        </div>
      )} */}
      </div>
    </div>
  );
}

export default AnalyseDetail;
