import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import AnalyseResponses from "../components/AnalysesPage/AnalyseResponses";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { setComplaintRespones } from "../redux/features/complaintResponsesSlice";
import Loading from "../components/Loading";
import ProgressBar from "../components/AnalysesPage/ProgressBar";

function AnalysesPage() {
  const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;
  const dispatch = useDispatch();
  const authState = useAuthHeader();
  const token = authState();
  const [percentage, setPercentage] = useState<number>(0);
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const config = {
    headers: {
      Authorization: token,
    },
  };

  const fetchPercentage = async (): Promise<void> => {
    try {
      const response = await await axios.get(
        `${BASE_URL}/api/openAi/customerFroms/complaintBundle/${id}`,
        config
      );
      setPercentage(response.data.data.progressPercent);
      dispatch(setComplaintRespones(response.data.data.bundle.complaints));
      if (response.data.data.progressPercent === 100) {
        clearInterval(intervalRef.current as NodeJS.Timeout); // Cast the type here
      }
    } catch (error) {
      if (error) {
        clearInterval(intervalRef.current as NodeJS.Timeout); // Cast the type here
      }
    }
  };

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    fetchPercentage();

    intervalRef.current = setInterval(() => {
      fetchPercentage();
    }, 5000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current as NodeJS.Timeout);
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-700 via-gray-900 to-black flex flex-col justify-between">
      <Navbar />
      {!percentage && <Loading />}
      {percentage && <ProgressBar percentage={percentage} />}
      {percentage && <AnalyseResponses />}
      <Footer />
    </div>
  );
}

export default AnalysesPage;
