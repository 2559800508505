import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo from "../assets/beyazcerelogo.svg";
import { useSignIn } from "react-auth-kit";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";
import {
  CodeResponse,
  GoogleLogin,
  GoogleOAuthProvider,
  TokenResponse,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import { setCompanyName, setUserName } from "../redux/features/userSlice";
import { useTranslation } from "react-i18next";

function LoginPage() {
  const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;
  const { t, i18n } = useTranslation();

  const signIn = useSignIn();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const clientId =
    "331611527191-q8bq3m7qq8e39c9j0sgl100up58vu92h.apps.googleusercontent.com";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}/api/auth/login`, {
        email: email,
        password: password,
      });

      if (
        signIn({
          token: response.data.access_token,
          tokenType: "Bearer",
          authState: { email: email },
          expiresIn: 120,
        })
      ) {
        if (response?.data?.userInfo?.company?.name?.length > 0) {
          dispatch(setCompanyName(response?.data?.userInfo?.company?.name));
        }

        if (response?.data?.userInfo?.name?.length > 0) {
          dispatch(setUserName(response?.data?.userInfo?.name));
        }

        navigate("/");
      }
      if (response) {
        setErrorMessage(null);
      }
    } catch (error: any) {
      setErrorMessage(
        `${t(`components.errors.${error?.response?.data?.errorCode}`)}`
      );
    }
  };

  const onSuccess = async (response: any) => {
    try {
      const res = await axios.get(
        `https://api.ceremeet.com/api/auth/google/callback/code/${response.access_token}`
      );

      if (
        signIn({
          token: res.data.access_token,
          tokenType: "Bearer",
          authState: res.data.userInfo.email,

          expiresIn: 120,
        })
      ) {
        if (res?.data?.userInfo?.company === null) {
          dispatch(setCompanyName(null));
        }

        if (res?.data?.userInfo?.company?.name?.length > 0) {
          console.log(res?.data?.userInfo?.company);

          dispatch(setCompanyName(res?.data?.userInfo?.company?.name));
        }

        if (res?.data?.userInfo?.name?.length > 0) {
          dispatch(setUserName(res?.data?.userInfo?.name));
        }

        navigate("/");
      }
    } catch (error: any) {
      setErrorMessage(
        `${t(`components.errors.${error?.response?.data?.errorCode}`)}`
      );
    }
  };

  const onFailure = (res: any) => {
    console.log("Login failed. Error:", res.error);
  };

  const options = {
    clientId,
    onSuccess,
    onFailure,
  };

  const login = useGoogleLogin(options) as any;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-700 via-gray-900 to-black py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="flex flex-col items-center ">
          <img src={logo} alt="cere" className="w-[200px]" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
            {t("components.forms.login.header")}{" "}
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={onSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={handleEmailChange}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder={`${t("components.forms.login.inputEmail")}`}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={handlePasswordChange}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder={`${t("components.forms.login.inputPwd")}`}
              />
            </div>
          </div>

          <div className="flex justify-between">
            {errorMessage && (
              <div className="flex items-center justify-between">
                <div className="text-red-500">
                  <h1>{errorMessage}</h1>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col space-y-2 items-center">
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent  rounded-md font-semibold text-base text-black bg-white focus:outline-none focus:ring-2 focus:
              focus:ring-offset-2 "
            >
              {t("components.forms.login.button.btnSubmit")}
            </button>
            <div
              className=" cursor-pointer w-full flex space-x-2 justify-center py-2 px-4 border border-transparent text-sm font-semidbold rounded-md bg-white focus:outline-none focus:ring-2 focus:
focus:ring-offset-2 "
              onClick={() => login()}
            >
              <FcGoogle size={24} />
              <h1 className="font-semibold text-base text-black ">
                {t("components.forms.login.button.google")}
              </h1>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
