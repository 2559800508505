import React from "react";
import { useTranslation } from "react-i18next";

interface ProgressBarProps {
  percentage: number;
}

function ProgressBar({ percentage }: ProgressBarProps) {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col w-4/6 mx-auto  justify-center">
      <div className="flex justify-between mb-1">
        <span className="text-lg font-semibold text-white dark:text-white">
          {t("components.progressBar.text1")}
        </span>
        <span className="text-base font-semibold text-white dark:text-white">
          {percentage}%
        </span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div
          className="bg-gradient-to-r from-green-200 via-green-400 to-green-500 h-2.5 rounded-full"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
}

export default ProgressBar;
