import React, { useState } from "react";
import complaint from "../assets/complaint.png";
import FormResponse from "./FormResponse";
import FormInputs from "./FormInputs";
import Uploader from "./Uploader";
import { useTranslation } from "react-i18next";
function UploadOptions() {
  const [showOneUploader, setShowOneUploader] = useState(false);
  const [showFileUploader, setShowFileUploader] = useState(false);
  const loading = true;
  const { t, i18n } = useTranslation();

  function openOneUploader() {
    setShowOneUploader(true);
    setShowFileUploader(false);
  }

  function openFileUploader() {
    setShowOneUploader(false);
    setShowFileUploader(true);
  }

  return (
    <div className="flex  mx-auto w-5/6 sm:w-4/6 border border-slate-400 rounded-lg mt-5 sm:p-3">
      <div className="flex flex-col w-full h-full p-3 rounded-lg bg-gradient-to-r from-gray-700 via-gray-900 to-black">
        <div className="flex items-center justify-center space-x-2">
          <div
            className="flex flex-col space-y-2 items-center justify-center border border-slate-400 p-2 cursor-pointer rounded-lg"
            onClick={openOneUploader}
          >
            <img src={complaint} alt="" className="w-[200px]" />
            <h1 className="text-center text-white text-xl font-medium w-[250px]">
              {t("components.uploadOptions.text1")}
            </h1>
          </div>

          <div
            className="flex flex-col space-y-2 items-center justify-center  border border-slate-400 p-2 cursor-pointer rounded-lg"
            onClick={openFileUploader}
          >
            <img src={complaint} alt="" className="w-[200px]" />
            <h1 className="text-center text-white text-xl font-medium w-[250px]">
              {t("components.uploadOptions.text2")}
            </h1>
          </div>
        </div>
        <div>
          {showOneUploader && (
            <div className="flex flex-col justify-between h-1/3 px-10">
              <FormInputs />
              {loading && <FormResponse />}
            </div>
          )}
          {showFileUploader && (
            <div className="flex items-center justify-center">
              <Uploader onFileUpload={() => {}} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadOptions;
