import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface complaintResponsesSliceState {
  complaintResponses: null | [any];
}

const initialState: complaintResponsesSliceState = {
  complaintResponses: null
};

export const complaintResponsesSlice = createSlice({
  name: "complaintResponses",
  initialState,
  reducers: {
    setComplaintRespones: (state, { payload }) => {
      state.complaintResponses = payload;
    }
  },
  extraReducers: (builder) => {}
});

export const { setComplaintRespones } = complaintResponsesSlice.actions;

export default complaintResponsesSlice.reducer;
