import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/beyazcerelogo.svg";
import { useTranslation } from "react-i18next";

function Footer({ prompter, ideas }: any) {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex  justify-center items-center w-full mx-auto flex-row flex-wrap md:w-4/6 border-t-2 border-slate-400 py-4">
      <div className="flex items-center justify-center py-4  ">
        <p className="text-white text-sm text-center">
          <span className=" text-lg font-medium">Cere</span> | © 2023 Cerebrum
          Technologies | All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
